<template>
  <div class="flex">
    <v-bank-account-table v-can="'FiConCoBan1'" />
    <v-categories-table v-can="'FiConCat1'" />
    <b-row align-v="stretch">
      <b-col class="col-md-12 col-lg-12">
        <v-card-flags-table v-can="'FiConFlag1'" />
      </b-col>
      <b-col class="col-md-12 col-lg-12">
        <v-card-machines-table v-can="'FiConMaq1'" />
      </b-col>
    </b-row>
    <v-payment-methods-table v-can="'FiConMetPag1'" />
    <v-cost-center-table />
    <v-preferences-table v-can="'FiConPref1'" />
  </div>
</template>
<script>
export default {
  name: 'Configuration',
  components: {
    'v-bank-account-table': () =>
      import('@/modules/financial/components/BankAccountTable'),
    'v-categories-table': () =>
      import('@/modules/financial/components/CategoriesTable'),
    'v-card-flags-table': () =>
      import('@/modules/financial/components/CardFlagsTable'),
    'v-card-machines-table': () =>
      import('@/modules/financial/components/CardMachinesTable'),
    'v-payment-methods-table': () =>
      import('@/modules/financial/components/PaymentMethodsTable'),
    'v-cost-center-table': () =>
      import('@/modules/financial/components/CostCenterTable'),
    'v-preferences-table': () =>
      import('@/modules/financial/components/PreferencesTable')
  }
}
</script>
